import { Injectable } from '@angular/core';
import { DrawerService } from 'src/app/shared/services/drawer.service';
@Injectable({
  providedIn: 'root'
})
export class NavigationLangService {
  pageName = {
    Dashboard: { EN: "Dashboard", AR: "لوحة القيادة" },
    Appointments: { EN: "Appointments", AR: "المواعيد" },
    Schedules: { EN: "Schedules", AR: "الجداول" },
    Myprofile: { EN: "My Profile", AR: "حسابي" },
    Edit_Myprofile: { EN: "Edit My Profile", AR: "تعديل ملفي الشخصي" },
    Settings: { EN: "Settings", AR: "الإعدادات" },
    Edit_Profile: { EN: "Edit Profile", AR: "تعديل الملف الشخصي" },
    Doctors: { EN: "Doctors", AR: "الأطباء" },
    Doctor_Profile: { EN: "Doctor Profile", AR: "ملف الطبيب" },
    Insurance: { EN: "Insurance", AR: "التأمين" },
    Logout: { EN: "Log Out", AR: "تسجيل الخروج" },
    Notifications: { EN: "Notifications", AR: " اخطارات" },
    Subscription: { EN: "Subscription", AR: "اشتراك" },
  };
  constructor(public drawer: DrawerService) {}
  public labelGetter(LabelKey: any) {
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (data) {
     const language = data.name;
    return this.pageName[LabelKey][language];
    }
  }
}
