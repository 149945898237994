import { Injectable } from '@angular/core';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { CommonLangService } from '../../common/common-lang.service';
@Injectable({
  providedIn: 'root'
})
export class SchedulesLangService extends CommonLangService {
  pageContentText = {
    Select_hospital: { EN: "Select Hospital", AR: "اختر المستشفى" },
    Start_time: { EN: "Start Time", AR: "وقت البدء" },
    End_time: { EN: "End Time", AR: "وقت الانتهاء" },
    Slot_duration: { EN: "Slot Duration", AR: "مدة الموعد" },
    Instructions: { EN: "Instructions", AR: "تعليمات" },
    Instructions_details: { EN: "Your schedule as provided by the hospital/clinic will automatically be synced with ePointments. However, if you would like to override that schedule, or if you work at a hospital/clinic which does not currently manage appointments digitally, you may use this page to edit/add schedules",
     AR: "سيتم مزامنة جدولك تلقائياً بين ePointments والمستشفى/العيادة. إذا كنت ترغب في تعديل هذا الجدول، أو إذا كنت تعمل في مستشفى/عيادة لا تدير المواعيد رقمياً في الوقت الحالي، يمكنك استخدام هذه الصفحة لإضافة أو تعديل جدولك" },
    Add_schedule: { EN: "Add Schedule", AR: "إضافة جدول" },
    Edit_schedule: { EN: "Edit Schedule", AR: "تعديل الجدول" },
    Select_start_date: { EN: "Select Start Date", AR: "تحديد تاريخ البدء" },
    Select_end_date: { EN: "Select End Date", AR: "تحديد تاريخ الانتهاء" },
    Select_days: { EN: "Select Days", AR: "تحديد الأيام" },
    Select_start_time: { EN: "Select Start Time", AR: "تحديد وقت البدء" },
    Select_end_time: { EN: "Select End Time", AR: "تحديد وقت الانتهاء" },
    Select_slot_duration: { EN: "Select Slot Duration", AR: "تحديد مدة الموعد" },
    min:{EN:"min", AR:"دقيقة"},
    Schedule_Available:{EN:"Schedule Added", AR:"تمت اضافة الجدول"},
    Schedules:{EN:"Schedules", AR:"الجداول"},

    Hospitals: { EN: "Hospitals", AR: "المستشفيات" },
    Select_Hospital: { EN: "Please Select Hospital", AR: "يرجى اختيار المستشفى" },

  };

  constructor(public drawer: DrawerService) {
    super(drawer)
    Object.assign(this.pageContentText, this.pageCommonText);
  }
  public labelGetter(LabelKey: any) {

    let result = "";
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if(data){
    const language = data.name;
  //   result = this.pageContentText[LabelKey][this.drawer.selectedLanguage];
      result = this.pageContentText[LabelKey][language];
    }

    return   result;
  }
}
