import { Injectable } from '@angular/core';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { CommonLangService } from '../../common/common-lang.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardLangService extends CommonLangService {
  pageContentText = {

    Cancel_Alert_Message: {EN:"Are you sure you want to cancel",AR:"هل أنت متأكد أنك تريد إلغاء"},
    Requestreceived: { EN: "Request received", AR: "تم تلقي طلب " },
    NoRequestreceived: { EN: "No requests received", AR: "لم يتم تلقي طلبات" },
    From: { EN: "from", AR: "من عند" },
    From_Hospitals: { EN: "from hospitals", AR: "من المستشفيات" },
    From_Doctors: { EN: "From Doctors", AR: "من الاطباء" },
    Tojoin: { EN: "to join", AR: "لينضم" },
    Join_btn: { EN: "Join", AR: "الإنضمام" },
    Reject_btn: { EN: "Reject", AR: "رفض" },
    View_all: { EN: "View All", AR: "عرض الكل" },
    Total_appointment: { EN: "Total Appointments", AR: "مجموع المواعيد" },
    Total_appoint: { EN: "Total Appointment", AR: "مجموع المواعيد" },
    Upcoming_appointment: { EN: "Upcoming Appointments", AR: "المواعيد القادمة" },
    Upcoming_appoint: { EN: "Upcoming Appointment", AR: "المواعيد القادمة" },
    Newrrequest: { EN: "New Requests", AR: "طلبات جديدة" },
    Newappointmentrequest: { EN: "New Appointment Requests", AR: "طلبات مواعيد جديدة" },
    Expiring_Date: { EN: "Expiry Date", AR: "تاريخ الانتهاء"},
    Registered: {EN: "Registered", AR:"مسجل"},
    Permitted: {EN: "Permitted", AR: "مسموح به"},
    Consultation_label: { EN: "Consultation fee", AR: "رسوم الاستشارة" },
    No_pending_request: {EN:"No pending requests", AR: "لا توجد طلبات قيد التأكيد"},
    Type_Here: { EN: "Type here", AR: "أكتب هنا" },
    Enter_Email_Address: { EN: "Enter Email ID", AR: " أدخل عنوان البريد الالكتروني" },
    Enter_Phone_Number: { EN: "Enter Phone Number", AR: "أدخل رقم الهاتف" },

  };
  constructor(public drawer: DrawerService) {
    super(drawer)
    Object.assign(this.pageContentText, this.pageCommonText);
  }
  public labelGetter(LabelKey: any) {

    let result = "";
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if(data){
    const language = data.name;
  //   result = this.pageContentText[LabelKey][this.drawer.selectedLanguage];

     result = this.pageContentText[LabelKey][language];
    }

    return   result;
  }
}
