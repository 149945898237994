import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/shared/services/api-handler.service';
import { Http } from '@angular/http';
import { API } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private apiHandler: ApiHandler,
    private http: Http) { }


  changePassword(formData) {
    return this.apiHandler.apiPost(API.USER_ENDPOINTS.CHANGE_PASSWORD, formData);
  }
  appointmentSetting(formData) {
    return this.apiHandler.apiUpdate(API.USER_ENDPOINTS.APPOINTMENT_SETTING, formData);

  }
  getSetting() {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.USER_SETTING_LIST);
  }
  notificationList(page: number, queryText: string) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.NOTIFICATION_LIST(page, queryText));
  }
  deleteNotification(formData) {
    return this.apiHandler.apiPost(API.COMMON_ENDPOINT.DELETE_NOTIFICATION, formData);
  }
}
