import { Injectable } from "@angular/core";
import { ApiHandler } from "src/app/shared/services/api-handler.service";
import { API } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class HospitalService {
  constructor(private apiHandler: ApiHandler) { }

  countriesList() {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.COUNTRIES_LIST);
  }

  countriesDataList(type) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.COUNTRIES_DATA_LIST(type));
  }

  subscriptionsPlanList() {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.SUBSCRIPTIONS_LIST);
  }
  hospitalList(page: number, queryText: string) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.HOSPITAL_LIST(page, queryText)
    );
  }
  addHospital(formData) {
    return this.apiHandler.apiPost(API.ADMIN_ENDPOINT.ADD_HOSPITAL, formData);
  }
  getHospital(id) {
    return this.apiHandler.apiGet(API.ADMIN_ENDPOINT.GET_HOSPITAL(id));
  }

  hospitalSubscriptionsPlan(page: number, queryText: string, hospitalId) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.HOSPITAL_SUBSCRIPTIONS_PLAN_LIST(page, queryText, hospitalId)
    );
  }
  deleteHospitalSubscriptionsPlan(id) {
    return this.apiHandler.apiDelete(API.ADMIN_ENDPOINT.DELETE_HOSPITAL_SUBSCRIPTIONS_PLAN(id));
  }

  hospitalDoctroList(page: number, queryText: string, hospitalId) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.HOSPITAL_DOCTOR_LIST(page, queryText, hospitalId)
    );
  }

  updateHospital(formData) {
    return this.apiHandler.apiUpdate(
      API.ADMIN_ENDPOINT.UPDATE_HOSPITAL,
      formData
    );
  }
  deleteHospital(id) {
    return this.apiHandler.apiDelete(API.ADMIN_ENDPOINT.DELETE_HOSPITAL(id));
  }

  updateHospitalStatus(formData, id) {
    return this.apiHandler.apiUpdate(
      API.ADMIN_ENDPOINT.UPDATE_HOSPITAL_STATUS(id),
      formData
    );
  }

  hospitalInvoiceList(page: number, queryText: string, hospitalId) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.HOSPITAL_INVOICE_LIST(page, queryText, hospitalId)
    );
  }
  updateInvoiceStatus(formData, id) {
    return this.apiHandler.apiPost(
      API.ADMIN_ENDPOINT.UPDATE_INVOICE_STATUS(id),
      formData
    );
  }

  hospitalAppointmentList(page: number, queryText: string, hospitalId) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.HOSPITAL_APPOINTMENT_LIST(page, queryText, hospitalId)
    );
  }
  getCSV(type) {
    return this.apiHandler.apiGetCSV(API.COMMON_ENDPOINT.GET_EXPORT_CSV(type));
  }
  getHospitalAppointmentCSV(id) {
    return this.apiHandler.apiGetCSV(API.COMMON_ENDPOINT.GET_HOSPITAL_APPOINTMENT_EXPORT_CSV(id));
  }

 }
