import { Injectable } from "@angular/core";
import { ApiHandler } from "src/app/shared/services/api-handler.service";
import { API } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class DoctorAppointmentService {

  constructor(private apiHandler: ApiHandler) { }


  doctorAppointment(type, page: number, queryText: string) {
    return this.apiHandler.apiGet(
      API.DOCTOR_ENDPOINT.APPOINTMENT_LIST(type, page, queryText)
    );
  }

  updateRequestStatus(formData) {
    return this.apiHandler.apiUpdate(API.DOCTOR_ENDPOINT.UPDATE_APPOINTMENT_STATUS, formData);
  }

  cancelAppointment(formData) {
    return this.apiHandler.apiUpdate(API.DOCTOR_ENDPOINT.CANCEL_APPOINTMENT, formData);
  }

  getPatientDetails(appointmentId, patientId) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_PATIENT_DETAIL(patientId, appointmentId));
  }

  updateNote(formData, appointmentID) {
    return this.apiHandler.apiUpdate(API.DOCTOR_ENDPOINT.UPDATE_NOTE(appointmentID),
      formData);
  }


}
