import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/shared/services/api-handler.service';
import { API } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class HospitalAppointmentService {

  constructor(public apiHandler: ApiHandler) { }

  appointmentList(page: number, queryText: string, type) {
    return this.apiHandler.apiGet(
      API.HOSPITAL_ENDPOINT.APPOINEMENT_LIST(page, queryText, type)
    );
  }
  swappedAppointmentList(page: number, queryText: string) {
    return this.apiHandler.apiGet(
      API.HOSPITAL_ENDPOINT.SWAPPED_APPOINEMENT_LIST(page, queryText)
    );
  }

  getPatientDetails(appointmentId, patientId) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_PATIENT_DETAIL(patientId, appointmentId));
  }

  cancelAppointmntByHospital(formData, id) {
    return this.apiHandler.apiUpdate(API.HOSPITAL_ENDPOINT.CANCEL_APPOINTMENT(id), formData);
  }

  getCSV(type) {
    return this.apiHandler.apiGetCSV(API.HOSPITAL_ENDPOINT.GET_EXPORT_CSV(type));
  }

}
