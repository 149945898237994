import { Injectable } from '@angular/core';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { CommonLangService } from '../../common/common-lang.service';


@Injectable({
  providedIn: 'root'
})
export class AppointmentsLangService extends CommonLangService {
  pageContentText = {
    Notes: { EN: "Notes", AR: "ملاحظات" },
    Enter_Note_ref: { EN: "Please enter note", AR: "لرجاء ادخال الملاحظة" },
    Not_for_ref: { EN: "Internal note for reference", AR: "ملاحظة داخلية للرجوع اليها" },
    Search_placeholder: { EN: "Search by Name", AR: "البحث بالاسم" },
    Cancel_Alert_Message: {EN:"Are you sure you want to cancel",AR:"هل أنت متأكد أنك تريد إلغاء"},
    New_Appointment_Time: {EN:"New Appointment Time",AR:"الموعد الجديد"},
    No_Note: {EN:"No notes available",AR:"لا توجد ملاحظات"},
    Associated_Hospital: {EN:"Associated Hospitals",AR:"المستشفيات المرتبطة"}


  }

  constructor(public drawer: DrawerService) {
    super(drawer)
    Object.assign(this.pageContentText, this.pageCommonText);
  }
  public labelGetter(LabelKey: any) {

    let result = "";
    if (this.pageContentText[LabelKey]) {
     const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
     if(data){
     const language = data.name;
   //   result = this.pageContentText[LabelKey][this.drawer.selectedLanguage];
     result = this.pageContentText[LabelKey][language];
     }
    }

    return result;

  }
}
