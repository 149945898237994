import { Injectable } from "@angular/core";
import { ApiHandler } from "src/app/shared/services/api-handler.service";
import { API } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class SubscriptionPlanService {
  constructor(private apiHandler: ApiHandler) {}

  hospitalList(){
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.COMMON_HOSPITAL_LIST);
  }
  suscriptionsPlanList(page: number, queryText: string) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.SUBSCRIPTION_PLAN_LIST(page, queryText)
    );
  }

  addSubscription(formData) {
    return this.apiHandler.apiPost(
      API.ADMIN_ENDPOINT.ADD_SUBSCRIPTION_PLAN,
      formData
    );
  }
  deleteSubscription(id) {
    return this.apiHandler.apiDelete(
      API.ADMIN_ENDPOINT.DELETE_SUBSCRIPTION(id)
    );
  }

  getSubscriptions(id) {
    return this.apiHandler.apiGet(API.ADMIN_ENDPOINT.GET_SUBSCRIPTION(id));
  }
  updateSubscription(formData) {
    return this.apiHandler.apiUpdate(
      API.ADMIN_ENDPOINT.UPDATE_SUBSCRIPTION,
      formData
    );
  }

  assignSubscriptionPlan(formData) {
    return this.apiHandler.apiPost(
      API.ADMIN_ENDPOINT.ASSIGN_SUBSCRIPTION_PLAN,
      formData
    );
  }

  getPayAsYouGoPrice() {
    return this.apiHandler.apiGet(API.ADMIN_ENDPOINT.GET_PAY_AS_YOU_GO_PRICE);
  }
}
