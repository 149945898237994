import { Injectable } from '@angular/core';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { CommonLangService } from '../../common/common-lang.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileLangService extends CommonLangService {
  pageContentText = {
    Update_Button: { EN: "Update", AR: "تحديث" },
    Save_Button: { EN: "Save", AR: "حفظ" },
    Cancel_Button: { EN: "Cancel", AR: "إلغاء" },
    Consultation_Fee: { EN: "Consultation Fee", AR: "رسوم الاستشارة" },
    Experience: { EN: "Experience", AR: "سنوات الخبرة" },
    Edit_Profile: { EN: "Edit Profile", AR: "تعديل الملف" },
    Profile_Details: { EN: "Profile Details", AR: "تفاصيل الملف" },
    Locations: { EN: "Locations", AR: "المواقع" },
    Insurance: { EN: "Insurance", AR: "التأمين" },
    Rating_Reviews: { EN: "Ratings & Reviews", AR: "التقييم والتعليقات" },
    Consultation_Label: { EN: "Consultation fee", AR: "رسوم الاستشارة" },
    Type_Here: { EN: "Type here", AR: "أكتب هنا" },
    Remove: { EN: "Remove", AR: "حذف" },
    Remove_Text: { EN: "Are you sure you want to remove?", AR: "هل أنت متأكد من أنك تريد إزالة؟" },
    Remove_Picture: { EN: "Are you sure you want to delete the picture?", AR: " هل تريد حذف الصورة؟" },
    Bio: { EN: "Bio", AR: " عن الطبيب" },
    Education: { EN: "Education", AR: " التحصيل العلمي" },
    Degree: { EN: "Degree", AR: "الشهادة" },
    University: { EN: "University", AR: "الجامعة" },
    Edit_Education: { EN: "Edit Education", AR: "تعديل معلومات التحصيل العلمي" },
    Edit_Certification: { EN: "Edit Certification", AR: "تعديل الشهادة" },
    Certification: { EN: "Certification", AR: "الشهادات" },
    Specialties: { EN: "Specialties", AR: "التخصصات" },
    Hospital_Name: { EN: "Hospital Name", AR: "اسم المستشفى" },
    Joining_Date: { EN: "Employment Dates", AR: "تواريخ التوظيف" },
    Insurance_Company: { EN: "Insurance Company", AR: "شركة التأمين" },
    Doctors_setting: {EN:"Doctor's settings", AR:"إعدادات الطبيب"},
    Appointment_pre:{EN:"Appointments preference", AR:"إعدادات المواعيد"},
    Schedule_Manage: {EN:"Schedule managed by", AR:"يدار الجدول من قبل"},
    Confirmed: {EN:"Confirmed", AR:"تم تأكيد"},
    Schedules:{EN:"Schedules",AR:"الجداول"},

    // Add: { EN: "Add", AR: "إضافة" },
    All_Ratings: { EN: "All Ratings", AR: "جميع التقييمات" },
    Reviews: { EN: "Reviews", AR: "تعليقات مرضى آخرين" },
    Review: { EN: "Review", AR: "إعادة النظر" },
    // edit-profile
    Hospital_Details: { EN: "Hospital Details", AR: "تفاصيل المستشفى" },
    Personal_Details: { EN: "Personal Details", AR: "تفاصيل شخصية" },
    Upload_Photo_Btn: { EN: "Upload Profile Picture", AR: "تحميل صورة" },
    Remove_Photo_Btn: { EN: "Delete Profile Picture", AR: "حذف الصورة" },
    Your_Profile_Is: { EN: "Your profile is", AR: "تم تعبئة" },
    Perc_Completed: { EN: "complete", AR: "من ملفك" },
    Profile_Completed_Now: { EN: "Complete your profile now in English and Arabic",
     AR: "أكمل ملفك الشخصي الآن باللغتين العربية والإنجليزية" },
    Email_Address: { EN: "Email ID", AR: "عنوان البريد الالكتروني" },
    Total_Experience: { EN: "Years of Experience", AR: "سنوات الخبرة" },
    Bio_Placeholder: { EN: "Say something about yourself", AR: "اكتب شيءً عن نفسك" },
    Certificates: { EN: "Certificates", AR: "الشهادات" },
    Other: { EN: "Other", AR: "آخر" },
    University_Name: { EN: "University Name", AR: "اسم الجامعة" },
    Select_Degree: { EN: "Select Degree", AR: "اختر درجة" },
    Specialty: { EN: "Specialty", AR: "التخصص" },
    Select_Specialty: { EN: "Select Specialty", AR: "اختر التخصص" },
    Contact_Person_Name: { EN: "Contact Person Name", AR: "اسم شخص التواصل" },
    Enter_Contact_Person_Name: { EN: "Enter Contact Person Name", AR: "ادخل اسم شخص التواصل" },
    Enter_Hospital_Name: { EN: "Enter Hospital Name", AR: "ادخل اسم المستشفى" },
    Enter_Email_Address: { EN: "Enter Email ID", AR: "عنوان البريد الالكتلروني" },
    Enter_Phone_Number: { EN: "Enter Phone Number", AR: "الرجاء ادخال رقم الهاتف" },
    Enter_Website_Link: { EN: "Enter Website Link (example: https://www.e-pointments.com)",
     AR: "ادخل رابط الموقع (مثال: https://www.e-pointments.com)" },
    Enter_Contact_Address: { EN: "Enter Contact Address", AR: "أدخل عنوان الاتصال" },
    Enter_Address: { EN: "Enter Address", AR: "أدخل العنوان" },
    Enter_city: { EN: "Enter City", AR: "دخول المدينة" },
    Enter_First_Name: { EN: "Enter First Name", AR: "أدخل الاسم الأول" },
    Enter_Last_Name: { EN: "Enter Last Name", AR: "أدخل اسم العائلة" },
    Enter_Total_Experience:{ EN: "Enter Total Years of Experience", AR: "أدخل مجموع سنوات الخبرة" },
    Contact_Address: { EN: "Contact Address", AR: "عنوان الإتصال" },
    Address: { EN: "Address", AR: "العنوان" },
    Website_Link: { EN: "Website Link", AR: "رابط الموقع" },
    Brief_Description : { EN: "Brief Description", AR: "نبذة عن المستشفى" },
    Say_Something : { EN: "Say something about the hospital", AR: "اكتب نبذة عن المستشفى" },
    Doctor_Name:{EN:"Dr. Fuaad al Vaziri", AR:"د. فؤاد ال فزيري"},



    Edit_Other: {EN:"Edit Other Detail", AR:"تعديل التفاصيل الأخرى"},
    Enter_Valid_Address: {EN:"Please enter valid address", AR:"الرجاء إدخال عنوان صالح"},





  }

  constructor(public drawer: DrawerService) {
    super(drawer)
    Object.assign(this.pageContentText, this.pageCommonText);
  }
  public labelGetter(LabelKey: any) {
    let result = "";
    if (this.pageContentText[LabelKey]) {
     const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
     if(data){
     const language = data.name;
   //   result = this.pageContentText[LabelKey][this.drawer.selectedLanguage];
     result = this.pageContentText[LabelKey][language];
     }
    }

    return result;
  }
}
