import { Injectable } from '@angular/core';
import { DrawerService } from 'src/app/shared/services/drawer.service';
import { CommonLangService } from '../../common/common-lang.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsLangService extends CommonLangService {
  pageContentText = {
    Delete_Alert_Message: { EN: "Are you sure you want to delete this entry?", AR: "هل تريد حذف هذا الإدخال؟" },
    Load_More: { EN: "Load more", AR: "تحميل المزيد" },
    Notifications: { EN: "Notifications", AR: "إخطارات" },
  }
  constructor(public drawer: DrawerService) {
    super(drawer)
    Object.assign(this.pageContentText, this.pageCommonText);
  }
  public labelGetter(LabelKey: any) {
    let result = "";
    if (this.pageContentText[LabelKey]) {
     const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
     if(data){
     const language = data.name;
   //   result = this.pageContentText[LabelKey][this.drawer.selectedLanguage];
     result = this.pageContentText[LabelKey][language];
     }
    }

    return result;
  }
}
