import { Injectable } from '@angular/core';
import { ApiHandler } from "src/app/shared/services/api-handler.service";
import { API } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AdminPatientService {

  constructor(private apiHandler: ApiHandler) { }


  patientList(page: number, queryText: string) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.PATIENT_LIST(page, queryText)
    );
  }
  updatePatientStatus(id, status) {
    return this.apiHandler.apiUpdate(API.ADMIN_ENDPOINT.UPDATE_PATIENT_STATUS(id, status), FormData);
  }

  patientDetails(id, type) {
    return this.apiHandler.apiGet(API.ADMIN_ENDPOINT.PATIENT_DETAIL(id, type));

  }
  patientAppointment(page: any, queryText: string, id) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.PATIENT_APPOINTMENT_LIST(page, queryText, id)
    );
  }
  patientAppointmentDetail(patientId, appointmentId) {
    return this.apiHandler.apiGet(API.ADMIN_ENDPOINT.PATIENT_APPOINTMENT_DETAIL(patientId, appointmentId));

  }
  getCSV(type) {
    return this.apiHandler.apiGetCSV(API.COMMON_ENDPOINT.GET_EXPORT_CSV(type));
  }
}
