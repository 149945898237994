import { Injectable } from "@angular/core";
import { DrawerService } from "src/app/shared/services/drawer.service";
import { CommonLangService } from "../../common/common-lang.service";
@Injectable({
  providedIn: "root"
})
export class SettingsLangService extends CommonLangService {
  pageContentText = {
    Appointment_setting: { EN: "Appointment Settings", AR: "إعدادات المواعيد" },
    Key: { EN: "Key", AR: "مفتاح" },
    Secret: { EN: "Secret", AR: "سر" },
    Appointment_para: { EN: "Please select your preferred appointment settings below", AR: "الرجاء تحديد إعدادات المواعيد أدناه" },
    Confirmed_radio_dtl: { EN: "Patients can use ePointments to take appointments which are confirmed. You will be able to cancel appointments either through this portal or by calling the patient directly.",
    AR: "يمكن للمرضى استخدام ePointments لحجز مواعيد مثبتة في الحال. يمكنك إلغاء المواعيد إما من خلال هذه المنصة أو عن طريق الاتصال بالمريض مباشرة" },
    Requested_radio_dtl: { EN: "Patients can use ePointments to request an appointment. You will have to accept the appointment to confirm it, or you may reject the appointment request.",
     AR: "يمكن للمرضى استخدام ePointments لطلب موعداً منك (لن يثبت تلقائياً). سيتوجب عليك الرد على المريض وقبول الموعد لتأكيده، أو يمكنك رفض الطلب" },
    Change_password: { EN: "Change Password", AR: "تغيير كلمة السر" },
    Change_password_para: { EN: "You can change your password below", AR: "يمكنك تغيير كلمة السر أدناه" },
    Current_Password: { EN: "Current Password", AR: "ادخل كلمة السر الحالية" },
    New_Password: { EN: "New Password", AR: "ادخل كلمة سر جديدة" },
    Confirm_New_Password:{EN:"Confirm New Password", AR:"الرجاء تأكيد كلمة السر"},
    Reschedule_Appointment: { EN: "Reschedule Appointment", AR: "اعادة جدولة الموعد" },

    Cancel_Appointment: { EN: "Cancel Appointment", AR: "إلغاء الموعد" },
    Can_Doctor_Manage : { EN: "Are doctors able to manage (add/edit) their own schedule on ePointments?",
     AR: "هل يسمح للأطباء إدارة (إضافة/تعديل) جدولهم على ePointments؟" },

    Api_details: {EN: "API Key for setting up the connection: ", AR: "مفتاح API لإعداد الاتصال:"},

    Patient_appointment_settings: {EN: "Patient Appointment Settings", AR: "اعدادات إلغاء واعادة جدولة المواعيد"},
    Patient_appointment_settings_para: {EN: "Please select up to how long before a scheduled appointment patients are able to reschedule and cancel their appointments (using ePointments)",
    AR: "يرجى تحديد المدة الزمنية التي يُسمح فيها للمريض اعادة جدولة موعده أو إلغائه (قبل وقت بدء الموعد) بإستخدام ePointments"},

  };
  constructor(public drawer: DrawerService) {
    super(drawer);
    Object.assign(this.pageContentText, this.pageCommonText);
  }
  public labelGetter(LabelKey: any) {

    let result = "";
    const data = JSON.parse(localStorage.getItem("selectedLanguageData"));
    if (data) {
    const language = data.name;
  //   result = this.pageContentText[LabelKey][this.drawer.selectedLanguage];

     result = this.pageContentText[LabelKey][language];
    }

    return   result;
  }
}
