import { Injectable } from "@angular/core";
import { ApiHandler } from "src/app/shared/services/api-handler.service";
import { API } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class HospitalDoctorService {

  constructor(private apiHandler: ApiHandler) { }

  addDoctor(formData) {
    return this.apiHandler.apiPost(API.HOSPITAL_ENDPOINT.ADD_DOCTOR, formData);
  }
  allSpecialitiesList() {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_SPECIALTIES_LIST);
    }

  doctorList(page: number, queryText: string) {
    return this.apiHandler.apiGet(
      API.HOSPITAL_ENDPOINT.DOCTOR_LIST(page, queryText)
    );
  }
  searchDoctorList(page: number, queryText: string) {
    return this.apiHandler.apiGet(
      API.HOSPITAL_ENDPOINT.SEARCH_DOCTOR_LIST(page, queryText)
    );
  }
  sendRequestToDoctor(id) {
    return this.apiHandler.apiGet(API.HOSPITAL_ENDPOINT.SEND_REQUEST_DOCTOR(id));
    }

  updateStatus(formData) {
    return this.apiHandler.apiUpdate(API.HOSPITAL_ENDPOINT.UPDATE_DOCTOR_STATUS,
      formData);
  }


  updateRequestStatus(formData) {
    return this.apiHandler.apiUpdate(API.HOSPITAL_ENDPOINT.UPDATE_REQUEST_STATUS, formData);
  }

  getDoctorProfile(doctorId) {
    return this.apiHandler.apiGet(API.HOSPITAL_ENDPOINT.GET_DOCTOR_PROFILE(doctorId));

  }
  getDoctorCompleteDetail(id) {
    return this.apiHandler.apiGet(API.HOSPITAL_ENDPOINT.GET_DOCTOR_COMPLETED_DETAILS(id));
  }
  getDoctorRatingList(page: number, doctorUserId) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_DOCTOR_RATING_LIST(page, doctorUserId)
    );
  }

  getDoctorInsuranceList(page: number, queryText: string, doctorUserId, hospitalId) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_DOCTOR_INSURANCE_LIST(page, queryText, doctorUserId, hospitalId)
    );
  }
  updateDoctor(formData, doctorId, hospitalId) {
    return this.apiHandler.apiUpdate(API.HOSPITAL_ENDPOINT.UPDATE_DOCTOR_PROFILE(doctorId, hospitalId), formData);
  }

  // schedule
  addSchedule(formData) {
    return this.apiHandler.apiPost(API.DOCTOR_ENDPOINT.ADD_SCHEDULE, formData);
  }

  getSchedules(hospitalId, doctorId, date) {
    return this.apiHandler.apiGet(API.DOCTOR_ENDPOINT.GET_SCHEDULE(hospitalId, doctorId, date));
  }
  deleteSchedule(scheduleGroupId) {
    return this.apiHandler.apiDelete(API.DOCTOR_ENDPOINT.DELETE_SCHEDULE(scheduleGroupId));
  }
  getScheduleDetail(scheduleGroupId) {
    return this.apiHandler.apiGet(API.DOCTOR_ENDPOINT.GET_SCHEDULE_DETAIL(scheduleGroupId));
  }
  updateSchedule(formData, id) {
    return this.apiHandler.apiUpdate(API.DOCTOR_ENDPOINT.UPDATE_SCHEDULE(id), formData);
  }
  getDateWiseSchedule(date, doctorId, hospitalId) {
    return this.apiHandler.apiGet(API.DOCTOR_ENDPOINT.GET_SCHEDULE_BY_DATE(date, doctorId, hospitalId));
  }

  getCSV(type) {
    return this.apiHandler.apiGetCSV(API.HOSPITAL_ENDPOINT.GET_EXPORT_CSV(type));
  }

}
