import { Injectable } from '@angular/core';
import { ApiHandler } from "src/app/shared/services/api-handler.service";
import { API } from "src/environments/environment";


@Injectable({
  providedIn: "root"
})
export class AdminDoctorService {

  globalDoctorId: any;
  constructor(public apiHandler: ApiHandler) { }

  allSpecialitiesList() {
  return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_SPECIALTIES_LIST);
  }
  doctorList(page: number, queryText: string) {
    return this.apiHandler.apiGet(
      API.ADMIN_ENDPOINT.DOCTOR_LIST(page, queryText)
    );
  }

  deleteAdminDoctor(id) {
    return this.apiHandler.apiDelete(API.ADMIN_ENDPOINT.DELETE_DOCTOR(id));
  }

  doctorProfile(id) {
    return this.apiHandler.apiGet(API.ADMIN_ENDPOINT.GET_DOCTOR_PROFILE(id));

  }

  hospitalList(page: number, queryText: string, doctorUserId, type) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.HOSPITAL_LIST(page, queryText, doctorUserId, type)
    );
  }

  getDoctorInsuranceList(page: number, queryText: string, doctorUserId) {
    return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_DOCTOR_INSURANCE_LIST(page, queryText, doctorUserId)
    );
  }
  getDoctorRatingList(page: number,  doctorUserId) {
   return this.apiHandler.apiGet(API.COMMON_ENDPOINT.GET_DOCTOR_RATING_LIST(page, doctorUserId)
    );
  }

  getCSV(type) {
    return this.apiHandler.apiGetCSV(API.COMMON_ENDPOINT.GET_EXPORT_CSV(type));
  }


  deleteRating(id) {
    return this.apiHandler.apiDelete(API.ADMIN_ENDPOINT.DELETE_RATING(id));
  }
}
